<template>
  <div class="col-sm-4 form-group mb-4" :class="rootClassName">
    <label
      :class="[{ 'royalc-error-input': error }]" >
      {{ this.$i18n.t(label) }}
      <span><i class="ft-alert-circle"></i><md-tooltip md-direction="right">Opções de frete com esse número de contrato, não serão apresentados nas Cotações Online</md-tooltip></span>
    </label>
    <v-select name="nome"
              label="numeroContrato"
              :disabled="disabled"
              :clearable="false"
              :searchable="true"
              v-model="selected"
              @search="fetchOptions"
              :options="optionList"
              @input="onSelect" :filter-by="filter" >
      <template slot="no-options" slot-scope="{searching, search }">
        <em v-if="!searching" style="opacity: 0.5;">
          Digite para buscar um contrato de frete
        </em>
        <em v-else style="opacity: 0.5;">
          Nenhuma opção encontrada para {{ search }}
        </em>
      </template>
      <template slot="option" slot-scope="option">
        <div class="d-center">
          {{ option.numeroContrato }}
        </div>
      </template>
      <template slot="selected-option" slot-scope="option">
        <div class="selected d-center">
          {{ option.numeroContrato }}
        </div>
      </template>
    </v-select>
    <transition name="slide" mode="">
      <small v-show="error" class="royalc-error-field">{{ error }}</small>
    </transition>
  </div>
</template>

<script>
// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import ContratoFreteService from '@/services/ContratoFreteService'

export default {
  inheritAttrs: false,
  name: 'ContratoFreteField',
  components: {
    vSelect
  },
  created () {
    let _this = this
    _this.id = 'contrato_frete_' + _this._uid

    if (_this.autoLoad) {
      _this.fetchAutoLoad('')
    }
  },
  data () {
    return {
      optionList: [],
      id: null,
      timer: 0,
      selected: null
    }
  },
  $_veeValidate: {
    // value getter
    value () {
      return this.$el.value
    },
    // name getter
    name () {
      return this.name
    }
  },
  props: {
    autoLoad: {
      required: false,
      type: Boolean,
      default: true
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    delay: {
      required: false,
      type: Number,
      default: 400
    },
    value: {
      required: true
    },
    rootClassName: {
      type: String,
      required: false,
      default: ''
    },
    error: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false,
      default: 'form.tarifarioCotacao.numeroContrato'
    }
  },
  methods: {
    /**
     * Triggered when search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     * @param loading {Function} Toggle loading class
     */
    fetchOptions (search, loading) {
      if (search.length > 0) {
        let _this = this
        window.clearTimeout(this.timer)

        this.timer = window.setTimeout(function () {
          loading(true)
          let filters = {
            'searchTerm': search
          }

          ContratoFreteService.getContratosFrete(filters).then(res => {
            _this.optionList = res.data.data
            loading(false)
          })
        }, this.delay)
      }
    },
    fetchAutoLoad (search) {
      let _this = this

      let filters = {
        'searchTerm': search
      }

      ContratoFreteService.getContratosFrete(filters).then(res => {
        _this.optionList = res.data.data
      })
    },
    onSelect (val) {
      this.$emit('input', val)
    },
    filter (option, label, search) {
      let temp = search.toLowerCase()

      return ((option.nome && option.nome.toLowerCase().indexOf(temp) > -1) || (option.sigla && option.sigla.toLowerCase().indexOf(temp) > -1))
    }
  },
  watch: {
    value: function (newValue) {
      this.selected = newValue
    }
  }
}
</script>
