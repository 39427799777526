<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <form data-vv-scope="tarifarioCotacaoForm" method="post">
      <div class="row justify-content-md-center p-3">

        <div class="col-12 col-md-12">

          <!-- Número Contrato -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <!-- Unidade Medida -->
                <contrato-frete-field
                  label='form.tarifarioCotacao.numeroContrato'
                  :grey-field="false"
                  :auto-load="true"
                  root-class-name="col-12 col-md-4 form-group mb-4"
                  v-model="formData.idcontratoFrete"
                  name="formData.idcontratoFrete"
                  v-validate="'required'"
                  :error="errors.first('formData.idcontratoFrete')"
                />
              </div>
            </div>
          </div>

        </div>

        <!-- Submit -->
        <div class="col-12">
          <button type="button" class="btn btn btn-outline-light pull-right" @click="onSave">Salvar</button>
          <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onCancel">Cancelar</button>
        </div>

      </div>
    </form>
  </div>
</template>

<script>
// Components
import ContratoFreteField from '@/components/Forms/ContratoFreteField'

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Services
import TarifarioCotacaoService from '@/services/TarifarioCotacaoService'

export default {
  name: 'tarifarioCotacaoForm',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('sidebar.tarifarioCotacao') + ' - %s'
    }
  },
  data () {
    return {
      formData: {
        idcontratoFrete: null
      },
      formErrors: {},
      isLoading: true,
      fullPage: true,
      // List of options to select on select input
      submitted: false
    }
  },
  components: {
    Loading,
    ContratoFreteField
  },
  // Apply filters to local filter
  beforeMount () {
  },
  mounted () {
    // this.$store.dispatch('SetShowFilter', true)
    // this.$store.dispatch('setShowSearch', false)
  },
  created () {
    let _this = this
    _this.isLoading = true

    let promises = [

    ]

    Promise.all(promises).then((values) => {
      // If is edit populate values
      let id = this.$route.params.id

      if (id) {
        TarifarioCotacaoService.get(id).then((res) => {
          _this.formData = res.data.data
          _this.isLoading = false
        })
      } else {
        _this.isLoading = false
      }
    }).catch(() => {
      _this.isLoading = false
    })
  },
  methods: {
    onCancel (e) {
      let _this = this

      _this.$alertSwal.modalConfirm('', 'Você tem certeza de que deseja cancelar.<br />Está certo desta ação?', 'warning', 'Sim', 'Não', (result) => {
        if (result) {
          _this.isLoading = true
          _this.$router.push({ name: 'TarifarioCotacaoIndex' })
        }
      })
    },
    displayErrors (errors) {
      let formatedErrors = util.getFormErrors(errors)
      this.formErrors = formatedErrors
    },
    onSave (e) {
      var _this = this
      this.submitted = true
      _this.$validator.validateAll('tarifarioCotacaoForm').then(valid => {
        if (valid) {
          _this.isLoading = true

          let data = Object.assign({}, _this.formData)
          data.idcontratoFrete = data.idcontratoFrete.id

          let id = this.$route.params.id
          if (id) {
            TarifarioCotacaoService.put(id, data).then(response => {
              _this.$router.push({ name: 'TarifarioCotacaoIndex' })
            }).catch(err => {
              if (err.code === 400) {
                _this.displayErrors(err.errors)
              }
              _this.isLoading = false
            })
          } else {
            TarifarioCotacaoService.post(data).then(response => {
              _this.$router.push({ name: 'TarifarioCotacaoIndex' })
            }).finally(() => {
              _this.isLoading = false
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped>
  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
</style>
